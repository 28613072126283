<template>
  <div class="container">
    <list
      ref="list"
      :searchParams.sync="searchParams"
      :headers="headers"
      :searchUrl="searchUrl"
      @searchSuccess="searchSuccess"
    >
      <template #headerSlot> <v-button @click="goback">返回</v-button> </template>
      <template #searchSlot>
        <v-select2
          label="所属商家"
          placeholder="查询所属商家"
          v-model="searchParams.shopId"
          v-bind="shopIdParams"
        ></v-select2>
        <v-select2
          ref="stroeSelect2"
          label="所属门店"
          placeholder="查询所属门店"
          v-model="searchParams.storeId"
          :subjoin="storeIdSubjoin"
          :disabled="storeIdDisabled"
          v-bind="storeIdParams"
        ></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.disabled" @click="cellClick(scope.row)"
          >编辑</v-button
        >
        <v-button v-else @click="saveClick(scope.row)">保存</v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  Checkbox as ElCheckbox,
  CheckboxGroup as ElCheckboxGroup,
} from "element-ui";
import { createCheckboxGroupVNode, createMultiSelectVNode } from "common/vdom";
import {
  saveInfo,
  listInfo,
  getCategoryByShopId,
  getShopIdsURL,
  getStoreIdsURL,
} from "./api";
import { serviceRangeOpts, weekEntries } from "./map";
import Vue from "vue";

Vue.use(ElCheckbox);
Vue.use(ElCheckboxGroup);

export default {
  data() {
    return {
      searchUrl: listInfo,
      weekEntries,
      shopIdParams: {
        searchUrl: getShopIdsURL,
        request: {
          text: "busName",
          value: "id",
        },
        response: {
          text: "name",
          value: "id",
        },
      },
      storeIdParams: {
        searchUrl: getStoreIdsURL,
        request: {
          text: "storeName",
          value: "id",
        },
        response: {
          text: "name",
          value: "id",
        },
        method: "post",
      },
      searchParams: {
        shopId: undefined,
        storeId: undefined,
      },
      headers: [
        {
          width: "160px",
          label: "姓名",
          prop: "userName",
        },
        {
          minWidth: "300px",
          width: "280px",
          prop: "goods",
          label: "服务范围",
          formatter: (row, prop) => {
            return this.createMultiSelectVNode(row, prop);
          },
        },
        ...weekEntries.map((v) => {
          return {
            width: "150px",
            prop: v[0],
            label: v[1] + "排班",
            formatter: (row, prop) => {
              return this.createCheckboxGroupVNode(row, prop);
            },
          };
        }),
      ],
      serviceRangeOpts,
      goodsByStoreId: [],
      rowCache: new Set(),
    };
  },
  props: {
    showReturn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    storeIdSubjoin() {
      return {
        limitCnt: 20,
        shopId: this.searchParams.shopId,
      };
    },
    storeIdDisabled() {
      return !this.searchParams.shopId;
    },
  },
  mounted() {
    //   this.$on('update:selfModel',(data)=>{
    //       this.$refs[]
    //   })
    this.getCategoryByShopId();
  },
  methods: {
    goback(){
      this.$router.back()
    },
    returnClick() {
      this.$emit("returnClick");
    },
    toNumArr(v) {
      return v.split(",").map((v) => {
        return Number(v);
      });
    },
    fromEntries(row) {
      return Object.fromEntries([
        ...weekEntries.map((v) => {
          return [v[0], row[v[0]] ? this.toNumArr(row[v[0]]) : []];
        }),
      ]);
    },
    searchSuccess(res) {
      this.$refs.list.tableData = res.data.resultList.map((row) => {
        return {
          ...row,
          ...this.fromEntries(row),
        };
      });
      this.rowCache.clear();
    },
    getCategoryByShopId() {
      this.$axios
        .get(getCategoryByShopId, {
          params: {
            storeId: this.searchParams.storeId,
          },
        })
        .then((res) => {
          this.goodsByStoreId = res.data || [];
        });
    },
    saveInfo(postRow) {
      this.$axios
        .post(saveInfo, postRow)
        .then((res) => {
          if (res.status == 100) {
            this.$message.success("修改成功");
          }
        })
        .finally(() => {
          this.$refs.list.searchData();
        });
    },
    saveClick(row) {
      row.disabled = !row.disabled;

      let postRow = this.$refs["list"].tableData
        .filter((v) => {
          return Array.from(this.rowCache).includes(v.userId);
        })
        .map((v) => {
          let fromEntriesData = Object.fromEntries([
            ...weekEntries.map((prop) => {
              return [prop[0], v[prop[0]].toString()];
            }),
          ]);
          delete v.disabled;
          console.warn(fromEntriesData);
          return {
            ...v,
            category: v.goods,
            ...fromEntriesData,
          };
        });
      this.saveInfo(postRow);
    },
    cellClick(row) {
      this.rowCache.add(row.userId);
      row.disabled = !row.disabled;
    },
    createSelectVNode(row, prop) {
      return createSelectVNode(this, row, prop, {
        options: this.goodsByStoreId,
      });
    },
    createCheckboxGroupVNode(row, prop) {
      this.$set(
        row,
        prop,
        Object.prototype.toString.call(row[prop]).indexOf("Array") == -1
          ? row[prop]
            ? row[prop].split(",")
            : []
          : row[prop]
      );

      if (row.disabled === undefined) {
        this.$set(row, "disabled", true);
        // row.disabled = true;
      }
      return createCheckboxGroupVNode(this, row, prop, [
        {
          value: 1,
          label: "上午",
          checked: row[prop].includes(1),
        },
        {
          value: 2,
          label: "下午",
          checked: row[prop].includes(2),
        },
        {
          value: 3,
          label: "晚上",
          checked: row[prop].includes(3),
        },
      ]);
    },
    createMultiSelectVNode(row, prop) {
      return createMultiSelectVNode(this, row, prop, this.goodsByStoreId, {
        style: {
          width: "100%",
        },
        valueKey: "categoryId",
      });
    },
  },
};
</script>

<style lang='scss' scoped>
</style>