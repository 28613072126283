import { generateMapByOpts } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '正常',
    value: 1
  }, {
    text: '关闭',
    value: 0
  }
]

const serviceRangeOpts = [
  {
    text: '首次',
    value: 1
  },
  {
    text: '每次',
    value: 2
  }
]

const statusMap = generateMapByOpts(statusOps)

// 登录平台
const appTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '管理后台',
    value: -1
  },
  {
    text: '园区管理',
    value: 2
  },
  {
    text: '园区商家',
    value: 3
  }
]

const weekEntries = [
  ["mon", "周一"],
  ["tues", "周二"],
  ["wed", "周三"],
  ["thur", "周四"],
  ["fri", "周五"],
  ["sat", "周六"],
  ["sun", "周日"],
]
const appTypeMap = generateMapByOpts(appTypeOps)

export {
  statusOps,
  statusMap,
  appTypeOps,
  appTypeMap,
  serviceRangeOpts,
  weekEntries
}
